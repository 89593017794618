/* UPDATE: 30th APRIL 2021
USE THIS TO PRESERVE ADS PARAMS, ACCORDING TO REQUEST FROM ZAC
WE CAN USE LOCAL STORAGE TO KEEP IT, BUT IT SEEMS THE REQUEST IS KEEP IT IN URL
*/

import queryString from 'query-string';
import { createBrowserHistory } from 'history';

const preserveParams = [
    's', 'n', 'ref', 'email', 'tempId', 'utm', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'token', 'job_id', 'p_job_id', 'source', 'templateId', 'jobCode'
]

const preserveQuery = (history, location) => {
    const currentQuery = queryString.parse(history.location.search);
    if (currentQuery) {
        const preserved = {};
        preserveParams.map(p => {
            const v = currentQuery[p];
            if (v && !(p === 'token' && history.location.pathname === '/enterprise')) {
                preserved[p] = v;
            }
        });
        if (location && location.search) {
            Object.assign(preserved, queryString.parse(location.search));
        }
        if(location) location.search = queryString.stringify(preserved);
    }
    return location;
}

const createLocationDescriptorObject = (location, state) => {
    return typeof location === 'string' ? { pathname: location, state } : location;
}

const createPreserveQueryHistory = (createHistory) => {
    return (options) => {
        const history = createHistory(options);
        const oldPush = history.push, oldReplace = history.replace;
        history.push = (path, state) => oldPush.apply(
            history,
            path && path.includes && path.includes('redirect') ? [path] : [preserveQuery(history, createLocationDescriptorObject(path, state))]
        );
        history.replace = (path, state) => oldReplace.apply(
            history,
            path && path.includes && path.includes('redirect') ? [path] : [preserveQuery(history, createLocationDescriptorObject(path, state))]
        );

        return history;
    }
}

const history = createPreserveQueryHistory(createBrowserHistory)();
export default history;
